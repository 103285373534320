<template>
  <div>
    <!-- bannner图 -->
    <img src="@/assets/home/banner.png" alt="" class="banner" />
    <!-- 公司介绍 -->
    <div class="introduce">
      <div class="about-imgs">
        <img src="@/assets/home/about.png" alt="" class="about-img" />
        <img src="@/assets/home/intro.png" alt="" class="intro-logo" />
      </div>
      <p class="introduce-text">
        无锡市迪赛环境艺术设计事务所（南京金陵无锡院），是由国内顶级室内设计师创立，汇聚一批高素质资深设计师和才华横溢的专业人士与管理队伍，并拥有海外学术背景支撑，在公建设计领域取得傲人成绩。DESY
        不仅以大型体育场馆、展示空间、办公空间、文教医疗类的室内设计而闻名，在星级酒店、高端会所、地产空间等方面，也以其优秀的作品在各类国际国内大赛中屡获殊荣。DESY
        用极具前瞻性的视野和超前的设计理念为客户提供独一无二的方案，为客户创造非凡价值，十多年的专注耕耘，DESY
        已成为极具竞争力并值得信赖的室内设计全程服务机构。
      </p>
      <p class="text-en">
        Wuxi DESY Environmental Art Design Office (Nanjing Jinling Wuxi
        Institute) was founded by topdomestic interior designers, bringing
        together a group of sophisticated senior designers and talented
        professionals and management teams, supported by overseas academic
        backgrounds, have achieved impressive results in the field of public
        construction design. DESY is not only famous for the interior design of
        large stadiums, exhibition spaces, office, public culture, education and
        medical care space, but also in star rated hotels, high-end clubs, real
        estate spaces, etc., it has also won many awards in various
        international and domestic competitions for the excellent works. DESY
        provides customers with unique solutions with forward-looking vision and
        advanced concepts, and creates extraordinary value for customers. With
        morethan ten years of dedicated work, DESY has become a highly
        competitive and trustworthy full-service interior design agency.
      </p>
      <div class="build">
        <div class="build-text">ARCHITECTURE</div>
        <img src="@/assets/home/build.png" alt="" class="build-img" />
      </div>
    </div>
    <!-- 领域案例 -->
    <div class="img-show">
      <div
        v-for="(item, i) in cateList"
        :key="i"
        class="imgs"
        @mouseenter="item.showActive = true"
        @mouseleave="item.showActive = false"
        @click="goWorks(i)"
      >
        <span class="img-title" v-if="!item.showActive">{{ item.ename }}</span>
        <span class="img-title-active" v-else>{{ item.ename }}</span>
        <img :src="baseURL + item.image" alt="" class="img-item" />
        <div v-if="item.showActive" class="img-mask">
          <img src="@/assets/home/arrow.png" alt="" class="arrow-img" />
        </div>
      </div>
    </div>
    <!-- 创始人 -->
    <div class="fonder">
      <div class="fonder-left">
        <img src="@/assets/home/fonder.png" alt="" class="fonder-logo" />
        <img src="@/assets/home/master.png" alt="" class="master-logo" />
      </div>
      <div class="fonder-right">
        <div class="fonder-intro">
          <div class="fonder-name">
            <span class="ch-name">林燕</span>
            <span class="en-name">LIN YAN</span>
          </div>
          <div class="fonder-job">
            <p class="job-item">高级工程师</p>
            <p class="job-item">CIID高级室内建筑师</p>
            <p class="job-item">无锡江南大学环境艺术硕士</p>
            <p class="job-item">无锡市迪赛环境艺术设计事务所创始人</p>
            <p class="job-item">南京金陵建筑装饰有限责任公司（无锡院） 院长</p>
            <p class="job-item">2013第二届海峡两岸室内设计杰出设计师代表</p>
            <p class="job-item">澳门国际设计联盟杰出设计师代表</p>
            <p class="job-item">2016年度十大最具商业创意设计师</p>
            <p class="job-item">2019世界包豪斯设计大赛优秀导师</p>
            <p class="job-item">2019年度江苏省十佳杰出设计师</p>
            <p class="job-item">清华大学酒店设计高研班八期</p>
            <p class="job-item">亚太酒店设计协会理事</p>
            <p class="job-item">JS CIID 江苏省室内设计协会常务理事</p>
            <p class="job-item">WIID 无锡市室内设计师学会副会长</p>
            <p class="job-item">
              CIID 中国建筑协会室内设计分会第36（无锡）专业委员会副主任
            </p>
            <p class="job-item">WIID 无锡市室内设计学会专家委员会副主任</p>
            <p class="job-item">江苏省公共资源交易中心 设计类评标专家</p>
          </div>
        </div>
        <img src="@/assets/home/ly.png" alt="" class="ly-img" />
      </div>
    </div>
  </div>
</template>

<script>
import { getWorkCate } from "@/api";
export default {
  name: "Home",
  data() {
    return {
      baseURL: "http://adminwxdesy.wxjoi.com/",
      cateList: [],
    };
  },
  mounted() {
    this.getCate();
  },
  methods: {
    // 获取案例分类
    async getCate() {
      try {
        const result = await getWorkCate();
        this.cateList = result.list;
        this.cateList = this.cateList.map((item) => {
          this.$set(item, "showActive", false);
          return item;
        });
      } catch (error) {
        alert(error);
      }
    },
    goWorks(id) {
      this.$router.push({ path: "/works" });
      window.localStorage.setItem("selectedId", id * 1 + 1);
    },
  },
};
</script>
<style lang="less" scoped>
.banner {
  width: 100%;
  height: 320px;
  display: block;
}
.introduce {
  padding: 51px 43px;
  position: relative;
  .about-imgs {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    margin-bottom: 27px;
    .about-img {
      width: 116px;
      height: 29px;
      margin-right: 10px;
    }
    .intro-logo {
      width: 115px;
      height: 12px;
    }
  }
  .introduce-text {
    width: 345px;
    font-size: 7px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #1d1d1d;
    margin-bottom: 22px;
  }
  .text-en {
    font-size: 7px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #777777;
  }
  .build {
    position: absolute;
    top: 51px;
    right: 43px;
    .build-text {
      font-size: 12px;
      font-family: Arial;
      font-weight: 400;
      color: #000000;
    }
    .build-img {
      width: 289px;
      height: 87px;
    }
  }
}
.img-show {
  display: flex;
  justify-content: center;
  align-items: space-between;
  .imgs {
    width: 25%;
    height: 250px;
    position: relative;
    cursor: pointer;
    .img-title {
      font-size: 12px;
      font-family: Arial;
      font-weight: 400;
      color: #ffffff;
      white-space: nowrap;
      position: absolute;
      top: 11px;
      left: 50%;
      transform: translateX(-50%);
    }
    .img-title-active {
      font-size: 12px;
      font-family: Arial;
      font-weight: 400;
      color: #ffffff;
      white-space: nowrap;
      position: absolute;
      top: 39px;
      left: 50%;
      transform: translateX(-50%);
      z-index: 9;
    }
    .img-item {
      width: 100%;
      height: 100%;
    }
    .img-mask {
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.8);
      position: absolute;
      top: 0;
      left: 0;
      .arrow-img {
        position: absolute;
        width: 19px;
        height: 9px;
        bottom: 39px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 8;
      }
    }
  }
}
.fonder {
  padding: 51px 43px 35px 43px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  .fonder-left {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    position: relative;
    .fonder-logo {
      width: 136px;
      height: 29px;
      margin-top: 6px;
      display: block;
    }
    .master-logo {
      width: 115px;
      height: 12px;
      margin-top: 9px;
      display: block;
    }
  }
  .fonder-right {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding-left: 36px;
    border-left: 1px solid #dddddd;
    flex: 1;
    margin-left: 43px;
    .ch-name {
      font-size: 16px;
      font-family: PingFang SC;
      font-weight: bold;
      color: #2a2a2a;
      padding-right: 15px;
    }
    .en-name {
      font-size: 10px;
      font-family: Arial;
      font-weight: 400;
      color: #2a2a2a;
    }
    .fonder-job {
      margin-top: 9px;
      .job-item {
        font-size: 7px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #2a2a2a;
        padding-bottom: 5px;
        &:last-child {
          padding-bottom: 0px;
        }
      }
    }
    .ly-img {
      width: 181px;
      height: 194px;
    }
  }
}
</style>
